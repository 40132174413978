import React from "react"
import { Link, graphql } from "gatsby"

import Button from "../components/button"
import SEO from "../components/seo"
import Hero from "../components/hero"

class Success extends React.Component {
  render() {
    const { data } = this.props
    const pic = data.file.childImageSharp.fluid.src
    return (
      <>
        <SEO title="Success" />
        <Hero imageSrc={pic}
        minHeight="100vh">
          <h1>Thank you for your interest</h1>
          <h2>I will get back to you as soon as possible!</h2>
          <Link to="/">
            <Button>Go back home</Button>
          </Link>
        </Hero>
      </>
    )
  }
}

export default Success

export const pageQuery = graphql`
  query {
    file(name: {eq: "chair-sit"}) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            src
          }
        }
      }
    
  }
`
